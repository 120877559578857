import React from "react"
import { Container, Row, Col } from "reactstrap"

import "../../../styles/brands.css"
import img1 from "../../../images/page_3/brand_logo/1.png"
import img2 from "../../../images/page_3/brand_logo/2.png"
import img4 from "../../../images/page_3/brand_logo/4.png"
import img5 from "../../../images/page_3/brand_logo/5.png"
import img6 from "../../../images/page_3/brand_logo/6.png"
import img7 from "../../../images/page_3/brand_logo/7.png"
import img8 from "../../../images/page_3/brand_logo/8.png"
import img9 from "../../../images/page_3/brand_logo/9.png"
import img10 from "../../../images/page_3/brand_logo/10.png"
import img11 from "../../../images/page_3/brand_logo/11.png"
import img12 from "../../../images/page_3/brand_logo/12.png"
import img13 from "../../../images/page_3/brand_logo/13.png"
import img14 from "../../../images/page_3/brand_logo/14.png"
import img15 from "../../../images/page_3/brand_logo/15.png"
import img16 from "../../../images/page_3/brand_logo/16.png"
import img17 from "../../../images/page_3/brand_logo/17.png"
import img18 from "../../../images/page_3/brand_logo/18.png"
import img19 from "../../../images/page_3/brand_logo/19.png"
import img20 from "../../../images/page_3/brand_logo/20.png"
import img21 from "../../../images/page_3/brand_logo/21.png"
import img22 from "../../../images/page_3/brand_logo/22.png"
import img23 from "../../../images/page_3/brand_logo/23.png"
import img24 from "../../../images/page_3/brand_logo/24.png"

function Brands() {
  return (
    <Container className="" fluid>
      <Row className=" mb-5 bg-white d-flex align-items-center justify-content-center text-center">
        <Col className="mt-5 text-center">
          <h2 className=" mb-5 px-md-0 px-2 font-weight-bold text-orange">
            Ponemos a tu disposición marcas confiables para equipar tu hogar
          </h2>
          <img src={img1} alt="" className="imgs-sizing" />
          <img src={img2} alt="" className="imgs-sizing" />
          <img src={img4} alt="" className="imgs-sizing" />
          <img src={img5} alt="" className="imgs-sizing" />
          <img src={img6} alt="" className="imgs-sizing" />
          <img src={img7} alt="" className="imgs-sizing" />
          <img src={img8} alt="" className="imgs-sizing" />
          <img src={img9} alt="" className="imgs-sizing" />
          <img src={img10} alt="" className="imgs-sizing" />
          <img src={img11} alt="" className="imgs-sizing" />
          <img src={img12} alt="" className="imgs-sizing" />
          <img src={img13} alt="" className="imgs-sizing" />
          <img src={img14} alt="" className="imgs-sizing" />
          <img src={img15} alt="" className="imgs-sizing" />
          <img src={img16} alt="" className="imgs-sizing" />
          <img src={img17} alt="" className="imgs-sizing" />
          <img src={img18} alt="" className="imgs-sizing bg-black" />
          <img src={img19} alt="" className="imgs-sizing" />
          <img src={img20} alt="" className="imgs-sizing" />
          <img src={img21} alt="" className="imgs-sizing" />
          <img src={img22} alt="" className="imgs-sizing" />
          <img src={img23} alt="" className="imgs-sizing" />
          <img src={img24} alt="" className="imgs-sizing" />
        </Col>
      </Row>
    </Container>
  )
}
export default Brands
