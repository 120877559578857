import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Comm, Brands, Online } from "../components/sections/page_3"
const ThirdPage = () => (
  <Layout>
    <SEO title="Construye" />
    <Comm />
    <Online />
    <Brands />
    <div id="contacto" />
  </Layout>
)

export default ThirdPage
