import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

import "../../../styles/online.css"
import amazon from "../../../images/page_3/brand_logo/amazonlogo.png"
import mercadolibre from "../../../images/page_3/brand_logo/mercadolibrelogo.png"

import ScrollAnimation from "react-animate-on-scroll"

function Online() {
  return (
    <>
      <Container className="bg-secondary d-flex flex-column justify-content-center" fluid>
        <Row className="mt-5 align-items-center text-center">
          <Col className="text-center">
            <h2 className="text-white font-weight-bold">
              Visita nuestra tienda en línea
            </h2>
            <h1 className="mt-5 text-white font-weight-bold">
              <font color="#E4D72B">Contamos con</font> + de{" "}
              <font color="#E4D72B">150 productos</font>{" "}
            </h1>
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
            <Button tag="a" href="#contacto" color="primary" className="my-5">
              {" "}
              Visita nuestra tienda en línea
            </Button>
          </ScrollAnimation>
          </Col>
        </Row>
      </Container>

      <Container className=" d-flex flex-column justify-content-center" fluid>
        <Row className="mt-5 justify-content-center align-items-center text-center">
          <h1 className="px-1 font-weight-bold">
            Encuéntranos en otros canales
          </h1>
        </Row>
        <Row className="justify-content-center align-items-center text-center">
          <h3 className="my-3 px-4">Entra y ve nuestros productos</h3>
        </Row>

        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
          <Row className="justify-content-center align-items-center text-center">
            <Col sm={12} md={6} className="">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.amazon.com.mx/s?me=APK7S6K9X1NB5&marketplaceID=A1AM78C64UM0Y8"
              >
                <img src={amazon} alt="" className="amazn_log bg-white" />
              </a>
            </Col>

            <Col
              sm={12}
              md={6}
              className="justify-content-center align-items-center text-center my-3"
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://listado.mercadolibre.com.mx/_CustId_313140995"
              >
                <img
                  src={mercadolibre}
                  alt=""
                  className="merc_libre bg-white"
                />
              </a>
            </Col>
          </Row>
        </ScrollAnimation>
      </Container>
    </>
  )
}
export default Online
