import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

import "../../../styles/comm.css"

import ScrollAnimation from "react-animate-on-scroll"

function Comm() {
  return (
    <>
      <Container
        className="bg_commertial d-flex flex-column justify-content-center"
        fluid
      >
        <Row className="mt-md-5 mt-0 justify-content-center align-content-center text-center">
          <Col className="px-5">
            <h1 className="text-white">
              Mediante la{" "}
              <font color="#E4D72B" className="">
                comercialización{" "}
              </font>
              de diferentes <font color="#E4D72B">productos</font> y{" "}
              <font color="#E4D72B">servicios</font> para la{" "}
              <font color="#E4D72B">construcción</font>,{" "}
              <font color="#E4D72B">facilitamos</font> a nuestros{" "}
              <font color="#E4D72B">seres queridos</font> el{" "}
              <font color="#E4D72B">camino</font> para que{" "}
              <font color="#E4D72B">liberen</font> todo su{" "}
              <font color="">potencial.</font>
            </h1>
          </Col>
        </Row>
        <Row className="">
          <Col className="text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
              <Button tag="a" href="#contacto" color="primary" className="">
                {" "}
                Contáctanos
              </Button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Comm
